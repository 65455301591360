enum colors {
  background = '#F5F5F5',
  black = '#000',
  blackOpacity = '#00000061',
  blueGray = '#E4EBEE',
  darkBlue = '#0024FC',
  darkGray = '#2E3134',
  darkGrayOpacity = '#2E414580',
  darkGreen = '#107956',
  darkGreenOpacity = '#1079561A',
  gray = '#767676',
  green = '#27E2A4',
  labelGray = '#6A6F83',
  lightBlue = '#008AFC',
  lightBlueOpacity = '#008AFC1A',
  lightGray = '#DFDFDF',
  lightPurple = '#B342CE',
  mainBlue = '#0024FC',
  midGray = '#C3C7D1',
  neutralGray = '#CFCFCF',
  orange = '#F98445',
  purple = '#6E30E5',
  red = '#FF4D4D',
  redOpacity = '#FF4D4D1A',
  shadow = '#00000026',
  teal = '#0DCAD3',
  tealOpacity = '#0DCAD340',
  transparent = 'transparent',
  white = '#FFFFFF',
  whiteOpacity = '#FFFFFFCC',
  yellow = '#FCCA65',
  yellowHighlight = '#fcca65b0',
  whiteTenPercent = '#ffffff1a',
  whiteFiftyPercent = '#ffffff80',
}

export default colors;
