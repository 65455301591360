import { HTMLInputTypeAttribute, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Input, InputProps, Radio } from 'antd';
import { FieldInputProps, FormikTouched, FormikValues, getIn } from 'formik';

import { companyRequested } from 'state/slices/companySlice';
import { settingsRequested } from 'state/slices/designServices/designServicesSettingsSlice';
import { AppDispatch, RootState } from 'state/store/store';

import {
  renderNestedValidationMessage,
  validateNestedStatus,
} from 'helpers/utils/formValidationHelpers';

import colors from 'helpers/constants/colors';
import fontSizes from 'helpers/constants/fontSizes';
import fontWeights from 'helpers/constants/fontWeights';
import styled from 'styled-components';
import {
  fireSetbackInitialData,
  FORM_CONTROLS,
  obstructionSetbackInitialData,
  REQUIRED_ERROR,
} from './constants';
import FormProps from './PlanSetForms/props';
import {
  AddressWrapper,
  AdjustableProjectRow,
  BoldLabelTitle,
  CustomSelect,
  ErrorDiv,
  FormRow,
  InputWrapper,
  LabelTitle,
  ProjectRow,
  SetbackInput,
  SetbackInputWrapper,
  SetbackLabelTitle,
  SetbackWrapper,
  StyledPinIcon,
  StyledSection,
  Text,
} from './styledComponents';

type LabelProps = {
  htmlFor: string;
  title: string;
  bold?: boolean;
};

const Label = ({ htmlFor, title, bold = true }: LabelProps) => {
  const Title = bold ? <BoldLabelTitle>{title}</BoldLabelTitle> : <LabelTitle>{title}</LabelTitle>;
  return <label htmlFor={htmlFor}>{Title}</label>;
};

type FormInputProps = {
  title: string;
  fieldProps: FieldInputProps<any>;
  touched?: FormikTouched<FormikValues>;
  placeHolder?: string;
  id: string;
  className?: string;
  errorValidation?: string | boolean;
  blur?: Function;
  error?: boolean;
  filter?: boolean;
  ariaLabel?: string;
  tall?: boolean;
  customSelect?: boolean;
  options?: any;
  onChange?: Function;
  setFieldValue?: (field: string, value: string, shouldValidate?: boolean | undefined) => void;
  isSettingsPage?: boolean;
  type?: HTMLInputTypeAttribute;
  step?: string;
  min?: string;
  onKeyDown?: Function;
  defaultValue?: string;
  inputProps?: InputProps;
};
const FormInput = ({
  title,
  fieldProps,
  touched,
  placeHolder,
  id,
  inputProps,
  className,
  errorValidation,
  blur,
  filter,
  ariaLabel,
  tall,
  customSelect,
  options,
  onChange,
  defaultValue,
  setFieldValue,
  isSettingsPage = false,
  type = 'text',
}: FormInputProps) => {
  const dispatch: AppDispatch = useDispatch();
  const { currentUser } = useSelector((state: RootState) => state.users);
  const { company } = useSelector((state: RootState) => state.company);
  const { settingsData, isLoading } = useSelector(
    (state: RootState) => state.designServicesSettings
  );

  useEffect(() => {
    if (!company && currentUser?.companyId) {
      dispatch(companyRequested(currentUser.companyId));
    }
  }, [company, currentUser?.companyId, dispatch]);

  useEffect(() => {
    if (!isSettingsPage && currentUser?.companyId && settingsData === null && !isLoading) {
      dispatch(settingsRequested({ companyId: currentUser.companyId }));
    }
  }, [currentUser?.companyId, dispatch, isLoading, isSettingsPage, settingsData]);

  const defaultData = useMemo(() => {
    return settingsData?.find((item) => item.folderId === 'default');
  }, [settingsData]);

  useEffect(() => {
    if (defaultData && setFieldValue) {
      Object.entries({
        attachment: defaultData.attachment,
        racking: defaultData.racking,
        moduleInfo: defaultData.moduleInfo,
        inverterInfo: defaultData.inverterInfo,
      }).forEach(([key, value]) => {
        if (key && value && !fieldProps?.value && fieldProps.name === key && !getIn(touched, key)) {
          setFieldValue(key, value);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultData, setFieldValue]);

  return (
    <FormRow>
      <StyledSection>
        <Label htmlFor={id} title={title} />
        <InputWrapper>
          {customSelect ? (
            <CustomSelect
              {...fieldProps}
              id={id}
              onChange={onChange}
              className={className}
              onBlur={blur}
              variant={filter === true ? 'filter' : filter}
              aria-label={ariaLabel}
              tall={tall}
              options={options}
              placeholder={placeHolder}
              defaultValue={defaultValue}
            />
          ) : (
            <Input
              {...fieldProps}
              {...inputProps}
              id={id}
              className={className}
              placeholder={placeHolder}
              aria-label={ariaLabel}
              type={type}
            />
          )}
        </InputWrapper>
        <ErrorDiv>{errorValidation}</ErrorDiv>
      </StyledSection>
    </FormRow>
  );
};

type SetbackProps = {
  title: string;
  id: string;
  placeholder: string;
};

type FormSetbackInputProps = {
  setbacks: { title: string; id: string; placeholder: string }[];
  getFieldProps: FormProps['getFieldProps'];
  touched: FormProps['touched'];
  errors: FormProps['errors'];
  title: string;
  setbackName: typeof FORM_CONTROLS.FIRE_SETBACKS | typeof FORM_CONTROLS.OBSTRUCTION_SETBACKS;
  setFieldValue: FormProps['setFieldValue'];
  setFieldTouched: FormProps['setFieldTouched'];
  isSettingsPage?: boolean;
};

const SetbackLabel = ({ htmlFor, title }: LabelProps) => {
  return (
    <label htmlFor={htmlFor}>
      <SetbackLabelTitle>{title}</SetbackLabelTitle>
    </label>
  );
};

const FormSetbackInput = ({
  setbacks,
  getFieldProps,
  touched,
  errors,
  title,
  setbackName,
  setFieldValue,
  setFieldTouched,
  isSettingsPage = false,
}: FormSetbackInputProps) => {
  const dispatch: AppDispatch = useDispatch();
  const { currentUser } = useSelector((state: RootState) => state.users);
  const { company } = useSelector((state: RootState) => state.company);
  const { settingsData, isLoading } = useSelector(
    (state: RootState) => state.designServicesSettings
  );

  useEffect(() => {
    if (!company && currentUser?.companyId) {
      dispatch(companyRequested(currentUser.companyId));
    }
  }, [company, currentUser?.companyId, dispatch]);

  useEffect(() => {
    if (!isSettingsPage && currentUser?.companyId && settingsData === null && !isLoading) {
      dispatch(settingsRequested({ companyId: currentUser.companyId }));
    }
  }, [currentUser?.companyId, dispatch, isLoading, isSettingsPage, settingsData]);

  const defaultData = useMemo(() => {
    return settingsData?.find((item) => item.folderId === 'default');
  }, [settingsData]);

  const defaultSetbackData = useMemo(() => {
    return setbackName === 'fireSetbacks'
      ? { ...defaultData?.fireSetbacks }
      : { ...defaultData?.obstructionSetbacks };
  }, [defaultData, setbackName]);

  useEffect(() => {
    if (defaultSetbackData) {
      Object.keys(
        setbackName === 'fireSetbacks'
          ? { ...fireSetbackInitialData }
          : { ...obstructionSetbackInitialData }
      ).forEach((key) => {
        if (
          (!getFieldProps(`${setbackName}.${key}`).value ||
            getFieldProps(`${setbackName}.${key}`).value === 'undefined') &&
          !getIn(touched, `${setbackName}.${key}`)
        ) {
          setFieldValue(
            `${setbackName}.${key}`,
            String(defaultSetbackData[key as keyof typeof defaultSetbackData])
          );
        }
      });
    }
  }, [setFieldValue, defaultSetbackData, setbackName, getFieldProps, touched]);

  const formSetbacks = setbacks?.map(({ title, id, placeholder }: SetbackProps) => (
    <SetbackInputWrapper key={id}>
      <SetbackLabel htmlFor={id} title={title} />
      <SetbackInput
        {...getFieldProps(`${setbackName}.${id}`)}
        placeholder={placeholder}
        id={`${setbackName}.${id}`}
        type="number"
        step="1"
        min="0"
        onKeyDown={(e) => {
          if (isNaN(Number(e.key)) && e.key !== 'Backspace' && e.key !== 'Tab') {
            e.preventDefault();
          }
        }}
        onFocus={() => {
          if (getFieldProps(`${setbackName}.${id}`).value === '0') {
            setFieldValue(`${setbackName}.${id}`, '');
          }
          setFieldTouched(`${setbackName}.${id}`);
        }}
        onPaste={(e) => {
          if (isNaN(Number(e.clipboardData.getData('Text')))) {
            e.preventDefault();
          }
        }}
        aria-label={placeholder}
        className={validateNestedStatus(touched, errors, setbackName, id)}
      />
      <ErrorDiv>
        {renderNestedValidationMessage(touched, errors, setbackName, id) && REQUIRED_ERROR}
      </ErrorDiv>
    </SetbackInputWrapper>
  ));

  return (
    <FormRow>
      <Label htmlFor={setbackName} title={title} />
      <StyledSection>
        <SetbackWrapper>{formSetbacks}</SetbackWrapper>
      </StyledSection>
    </FormRow>
  );
};

type ProjectAddressProps = {
  address: string | undefined;
  id: string;
  title: string;
  isAdjustable?: boolean;
};

const ProjectAddress = ({ address, id, title, isAdjustable = false }: ProjectAddressProps) => {
  const children = (
    <StyledSection>
      <Label htmlFor={id} title={title} />
      <AddressWrapper>
        <StyledPinIcon data-testid="pin-icon" />
        <Text>{address}</Text>
      </AddressWrapper>
    </StyledSection>
  );
  return isAdjustable ? (
    <AdjustableProjectRow data-testid="adjustable-project-row">{children}</AdjustableProjectRow>
  ) : (
    <ProjectRow data-testid="project-row">{children}</ProjectRow>
  );
};

type RadioProps = {
  id: string;
  title: string;
  fieldProps: any;
};

const RadioYesNo = ({ id, title, fieldProps }: RadioProps) => {
  const { t } = useTranslation();

  return (
    <StyledSection>
      <Label htmlFor={id} title={title} />
      <Radio.Group {...fieldProps}>
        <Radio value="Yes">{t('Generic.yes')}</Radio>
        <Radio value="No">{t('Generic.no')}</Radio>
      </Radio.Group>
    </StyledSection>
  );
};

const DesignServicesStatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
  border: 1px solid ${colors.lightGray};
  border-radius: 1rem;
  background-color: ${colors.white};
  min-width: 90rem;
  max-width: 60%;
  padding: 3rem 2rem;

  h4 {
    align-self: flex-start;
    margin: 0 0 1rem 2.5rem;
    font-size: ${fontSizes.mediumSmall};
    font-weight: ${fontWeights.bold};
  }

  ul,
  ol {
    align-self: flex-start;
  }
`;

const DesignServicesStatusTitle = styled.h1`
  max-width: 70%;
  font-size: ${fontSizes.large};
  font-weight: ${fontWeights.semiBold};
  margin: 2rem 0 0.5rem 0;
  text-align: center;
`;

export {
  DesignServicesStatusTitle,
  DesignServicesStatusWrapper,
  FormInput,
  FormSetbackInput,
  ProjectAddress,
  RadioYesNo,
};
